@import '~@tabler/core/dist/css/tabler.min.css';
.ng-select.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: rgba(0, 20, 49, 0.12) rgba(0, 40, 100, 0.12) rgba(0, 50, 126, 0.12);
}
.ng-select.ng-select-opened > .ng-select-container:hover, .ng-select.ng-select-opened > .ng-select-container:focus {
  box-shadow: none;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #4a5056;
  border-width: 0 5px 5px;
  top: -2px;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover, .ng-select.ng-select-opened > .ng-select-container .ng-arrow:focus {
  border-color: transparent transparent rgba(0, 0, 0, 0.12);
}
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #006cd8;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f9f9f9;
}
.ng-select.ng-select-single .ng-select-container {
  height: 36px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
  top: 5px;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid rgba(0, 60, 151, 0.12);
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 7px;
  padding-top: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #006cd8;
  border-radius: 2px;
  font-size: 0.9em;
  margin-bottom: 5px;
  margin-right: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover, .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:focus {
  background-color: #005fbf;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #0053a5;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #0053a5;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-bottom: 5px;
  padding-left: 3px;
  top: 5px;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-select-container {
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
  min-height: 36px;
}
.ng-select .ng-select-container:hover, .ng-select .ng-select-container:focus {
  box-shadow: none;
}
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 10px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #b0b6bc;
}
.ng-select .ng-clear-wrapper {
  color: rgba(0, 0, 0, 0.12);
}
.ng-select .ng-clear-wrapper:hover .ng-clear, .ng-select .ng-clear-wrapper:focus .ng-clear {
  color: #d0021b;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
.ng-select .ng-arrow-wrapper {
  margin: 0 4px;
  width: 8px;
}
.ng-select .ng-arrow-wrapper:hover .ng-arrow, .ng-select .ng-arrow-wrapper:focus .ng-arrow {
  border-top-color: #4a5056;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #4a5056 transparent transparent;
  border-style: solid;
  border-width: 8px 4px 3px;
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid rgba(0, 40, 100, 0.12);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
}
.ng-dropdown-panel.ng-select-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-color: rgba(0, 60, 151, 0.12);
  margin-top: -1px;
  top: 100%;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ng-dropdown-panel.ng-select-top {
  border-bottom-color: rgba(0, 60, 151, 0.12);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  bottom: 100%;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  margin-bottom: 1px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-weight: 500;
  padding: 8px 10px;
  user-select: none;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #cee6ff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #006cd8;
  font-weight: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #006cd8;
  color: #fff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #cee6ff;
  color: #495057;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #e7e9eb;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */
}
.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}
.bs-datepicker bs-day-picker {
  float: left;
}
.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active, .bs-datepicker-btns button:hover, .bs-datepicker-btns button:focus, .bs-datepicker-btns button:active, .bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:focus {
  outline: none;
}
.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}
.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}
.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;
  /* .bs-datepicker-body table */
}
.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}
.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}
.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}
.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
}
.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}
.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
  color: #fff;
}
.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-body table td.active {
  position: relative;
}
.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}
.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}
.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after, .bs-datepicker-body table td.active.select-start span:after, .bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}
.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}
.bs-datepicker-body table td span[class*=select-], .bs-datepicker-body table td[class*=select-] span {
  border-radius: 50%;
  color: #fff;
}
.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #e9edf0;
}
.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 3px;
  left: 0;
}
.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 4px;
  left: 0;
}
.bs-datepicker-body table.days td.select-start + td.select-end:before, .bs-datepicker-body table.days td.select-start + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.select-end:before, .bs-datepicker-body table.days td.in-range + td.is-highlighted:before, .bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}
.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}
.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}
.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}
.bs-datepicker-body table.months td {
  height: 52px;
}
.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}
.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}
.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}
.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}
.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}
.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}
.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}
.bs-datepicker-container {
  padding: 15px;
}
.bs-datepicker .bs-media-container {
  display: flex;
}
@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}
.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}
.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}
.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;
}
.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}
.bs-datepicker-buttons .btn-today-wrapper {
  display: flex;
  flex-flow: row wrap;
}
.bs-datepicker-buttons .clear-right,
.bs-datepicker-buttons .today-right {
  flex-grow: 0;
}
.bs-datepicker-buttons .clear-left,
.bs-datepicker-buttons .today-left {
  flex-grow: 1;
}
.bs-datepicker-buttons .clear-center,
.bs-datepicker-buttons .today-center {
  flex-grow: 0.5;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}
.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px;
}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777;
}
.theme-default .btn-today-wrapper .btn-success, .theme-default .btn-clear-wrapper .btn-success {
  background-color: #777;
  border-color: #777;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:focus, .theme-default .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #616161;
  border-color: #616161;
}
.theme-default .btn-today-wrapper .btn-success:hover, .theme-default .btn-clear-wrapper .btn-success:hover {
  background-color: #6F6E6E;
  border-color: #6F6E6E;
}
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after, .theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}
.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}
.theme-green .btn-today-wrapper .btn-success, .theme-green .btn-clear-wrapper .btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-green .btn-today-wrapper .btn-success:focus, .theme-green .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.theme-green .btn-today-wrapper .btn-success:hover, .theme-green .btn-clear-wrapper .btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}
.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}
.theme-blue .btn-today-wrapper .btn-success, .theme-blue .btn-clear-wrapper .btn-success {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-blue .btn-today-wrapper .btn-success:focus, .theme-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #2AA8CD;
  border-color: #2AA8CD;
}
.theme-blue .btn-today-wrapper .btn-success:hover, .theme-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #3AB3D7;
  border-color: #3AB3D7;
}
.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td span.selected, .theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*=select-]:after, .theme-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5bc0de;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}
.theme-dark-blue .btn-today-wrapper .btn-success, .theme-dark-blue .btn-clear-wrapper .btn-success {
  background-color: #337ab7;
  border-color: #337ab7;
}
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-dark-blue .btn-today-wrapper .btn-success:focus, .theme-dark-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #266498;
  border-color: #266498;
}
.theme-dark-blue .btn-today-wrapper .btn-success:hover, .theme-dark-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #2C6FA9;
  border-color: #2C6FA9;
}
.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td span.selected, .theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after, .theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}
.theme-red .btn-today-wrapper .btn-success, .theme-red .btn-clear-wrapper .btn-success {
  background-color: #d9534f;
  border-color: #d9534f;
}
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-red .btn-today-wrapper .btn-success:focus, .theme-red .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #D23531;
  border-color: #D23531;
}
.theme-red .btn-today-wrapper .btn-success:hover, .theme-red .btn-clear-wrapper .btn-success:hover {
  background-color: #E33732;
  border-color: #E33732;
}
.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td span.selected, .theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*=select-]:after, .theme-red .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}
.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}
.theme-orange .btn-today-wrapper .btn-success, .theme-orange .btn-clear-wrapper .btn-success {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-orange .btn-today-wrapper .btn-success:focus, .theme-orange .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #ED9C29;
  border-color: #ED9C29;
}
.theme-orange .btn-today-wrapper .btn-success:hover, .theme-orange .btn-clear-wrapper .btn-success:hover {
  background-color: #FFAC35;
  border-color: #FFAC35;
}
.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td span.selected, .theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*=select-]:after, .theme-orange .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}