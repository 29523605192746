@import '../tools/tools';

$ng-select-highlight: #006cd8 !default;
$ng-select-primary-text: #495057 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: rgba(0, 40, 100, 0.12) !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #fff !default;
$ng-select-selected: $ng-select-highlight !default;
$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 36px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
$ng-select-arrow: #4a5056 !default;

.ng-select {
  &.ng-select-opened {
    >.ng-select-container {
      background: $ng-select-bg;
      border-color: darken($ng-select-border, 10) $ng-select-border lighten($ng-select-border, 5);

      &:hover,
      &:focus {
        box-shadow: none;
      }

      .ng-arrow {
        border-color: transparent transparent $ng-select-arrow;
        border-width: 0 5px 5px;
        top: -2px;

        &:hover,
        &:focus {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }

    &.ng-select-bottom {
      >.ng-select-container {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.ng-select-top {
      >.ng-select-container {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened)>.ng-select-container {
      border-color: $ng-select-highlight;

      // box-shadow: $ng-select-box-shadow;

    }
  }

  &.ng-select-disabled {
    >.ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;

      .ng-value-container {
        .ng-input {
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;
          top: 5px;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      >.ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-left: 7px;
        padding-top: 5px;

        .ng-value {
          background-color: $ng-select-selected;
          border-radius: 2px;
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          margin-right: 5px;

          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;

            &:hover,
            &:focus {
              background-color: darken($ng-select-selected, 5);
            }

            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);
            }

            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);
            }
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;
        }

        .ng-placeholder {
          padding-bottom: 5px;
          padding-left: 3px;
          top: 5px;
        }
      }
    }
  }

  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  .ng-select-container {
    align-items: center;
    background-color: $ng-select-bg;
    border: 1px solid $ng-select-border;
    border-radius: $ng-select-border-radius;
    min-height: $ng-select-height;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;

      .ng-placeholder {
        color: $ng-select-placeholder;
      }
    }
  }

  .ng-clear-wrapper {
    color: darken($ng-select-border, 20);

    &:hover .ng-clear,
    &:focus .ng-clear {
      color: #d0021b;
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }

  .ng-arrow-wrapper {
    margin: 0 4px;
    width: 8px;

    &:hover,
    &:focus {
      .ng-arrow {
        border-top-color: $ng-select-arrow;
      }
    }

    .ng-arrow {
      border-color: $ng-select-arrow transparent transparent;
      border-style: solid;
      border-width: 8px 4px 3px;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-bg;
  border: 1px solid $ng-select-border;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;

  &.ng-select-bottom {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-color: lighten($ng-select-border, 10);
    margin-top: -1px;
    top: 100%;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &.ng-select-top {
    border-bottom-color: lighten($ng-select-border, 10);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    bottom: 100%;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    margin-bottom: 1px;

    .ng-optgroup {
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
      font-weight: 500;
      padding: 8px 10px;
      user-select: none;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        font-weight: normal;
      }
    }

    .ng-option {
      background-color: $ng-select-bg;
      color: rgba(#000, 0.87);
      padding: 8px 10px;

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        color: $ng-select-bg;

        .ng-option-label {
          font-weight: normal;
        }
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }

      &.ng-option-disabled {
        color: lighten($ng-select-primary-text, 60);
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
  }
}
